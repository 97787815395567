import { useMst } from 'app/store'
import { observer } from 'mobx-react'
import { useEffect, useContext, Fragment } from 'react'
import { useParams } from 'react-router'
import { underscoreToSpace } from 'utils/helpers'
import { client } from 'app/withApollo'
import gql from 'graphql-tag'
import { RouterContext } from './Router'

const StateFetcher = observer(({ children }) => {
  const {
    store: { getTally, getBha, getLinerTally, getCasingTally, getTripSheet, getTrendSheet, getKillSheet, getWellM, getWellSectionM, getTubulars },
  } = useMst()

  const { locationState, setLocationState } = useContext(RouterContext)
  const { wellName, wellSectionName } = useParams()

  let _wellId = ''
  let _wellSectionId = ''
  let _wellSectionType = ''
  let _units = ''
  let _rigId = ''
  let _rigType = ''

  useEffect(() => {
    let currentWellSectionName = wellSectionName

    if (!wellSectionName) {
      currentWellSectionName = ''
    }

    const variables = {
      wellName: underscoreToSpace(wellName),
      wellSectionName: underscoreToSpace(currentWellSectionName),
      companyId: JSON.parse(localStorage.getItem('context')).company.id,
      rigId: JSON.parse(localStorage.getItem('context')).rig.id,
    }

    getTubulars({ rigId: variables.rigId})

    if (wellName) {
      getWellM(variables)
    }

    if (wellSectionName) {
      getTally(variables)
      getBha(variables)
      getLinerTally(variables)
      getCasingTally(variables)
      getTripSheet(variables)
      getTrendSheet(variables)
      getKillSheet(variables)
      getWellSectionM(variables)
    }

    getEnvProps()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wellName, wellSectionName, getTally])

  async function getWellProps() {
    let currentWellSectionName = wellSectionName

    if (!wellSectionName) {
      currentWellSectionName = ''
    }

    const variables = {
      wellName: underscoreToSpace(wellName),
      wellSectionName: underscoreToSpace(currentWellSectionName),
      companyId: JSON.parse(localStorage.getItem('context')).company.id,
      rigId: JSON.parse(localStorage.getItem('context')).rig.id,
    }

    await client
    .query({
      query: gql`
        query wellByName(
          $wellName: String!
          $companyId: String!
          $rigId: String!
        ) {
          wellByName(
            wellName: $wellName
            companyId: $companyId
            rigId: $rigId
          ) {
            id
            data
            units
          }
        }
      `,
      variables,
    })
    .then((res) => {
      if (res?.data?.wellByName) {
        _wellId = res.data.wellByName.id
        _units = res.data.wellByName.units
        return res
      }
    })
  }

  async function getRigProps() {
    const variables = {
      companyId: JSON.parse(localStorage.getItem('context')).company.id,
      rigId: JSON.parse(localStorage.getItem('context')).rig.id,
    }

    await client
    .query({
      query: gql`
        query rigById(
          $companyId: String!
          $rigId: String!
        ) {
          rigById(
            companyId: $companyId
            rigId: $rigId
          ) {
            id
            name
            type
          }
        }
      `,
      variables,
    })
    .then((res) => {
      if (res?.data?.rigById) {
        _rigId = res.data.rigById.id
        _rigType = res.data.rigById.type

        return res
      }
    })
  }

  async function getWellSectionProps() {
    let currentWellSectionName = wellSectionName

    if (!wellSectionName) {
      currentWellSectionName = ''
    }

      const variables = {
        wellName: underscoreToSpace(wellName),
        wellSectionName: underscoreToSpace(currentWellSectionName),
        companyId: JSON.parse(localStorage.getItem('context')).company.id,
        rigId: JSON.parse(localStorage.getItem('context')).rig.id,
      }

      await client
      .query({
        query: gql`
          query wellSectionByName(
            $wellSectionName: String!
            $wellName: String!
            $companyId: String!
            $rigId: String!
          ) {
            wellSectionByName(
              wellName: $wellName
              wellSectionName: $wellSectionName
              companyId: $companyId
              rigId: $rigId
            ) {
              id
              data
              type
            }
          }
        `,
        variables,
      })
      .then((res) => {
        if (res?.data?.wellSectionByName) {
          _wellSectionId = res.data.wellSectionByName.id
          _wellSectionType = res.data.wellSectionByName.type

          if (res.data.wellSectionByName.type === 'OH') {
            getBha(variables)
          }

          return res
        }
      })
    }

  async function getEnvProps() {
      try {
        await getWellProps();
        await getRigProps()
        await getWellSectionProps()
        setLocationState({
          ...locationState,
          rigId: _rigId,
          rigType: _rigType,
          wellId: _wellId,
          // well: res.data.wellByName.data,
          units: _units,
          wellSectionId: _wellSectionId,
          // wellSection: response.data.wellSectionByName.data,
          wellSectionType: _wellSectionType
        })
    }
    catch (err) {
      console.log(err)
    }
  }

  return <Fragment>{children}</Fragment>
})

export default StateFetcher
